:root {
  --color-primary-purple: #b400ff;
  --color-primary-blue-light: rgba(6, 107, 251, 0.05);
  --color-primary-blue-dark: rgba(42, 84, 195, 1);
  --color-primary-blue: #066bfb;
  --color-primary-blue-hover: #003fa5;

  --color-neutral-300: #767676;

  --color-text-dark: #000000;
  --color-text-grey-dark: #9a9a9a;
  --color-text-grey-light: #d3d3d3;
  --color-text-light: #ffffff;
  --color-text-disable: #969696;

  --color-element-disable: #f0f0f0;

  --color-success: #09d8bf;
  --color-warning: #ffd508;
  --color-danger: #ff579c;

  --z-index-low: -1;

  --z-index-base: 1;
  --z-index-xBase: 2;
  --z-index-xxBase: 3;
  --z-index-xxxBase: 4;

  --z-index-medium: 5;
  --z-index-xMedium: 6;
  --z-index-xxMedium: 7;
  --z-index-xxxMedium: 8;

  --z-index-large: 9;
  --z-index-xLarge: 10;
  --z-index-xxLarge: 11;
  --z-index-xxxLarge: 12;

  --z-index-root: 999;
}

// 1em = 16px
$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
*,
*::after,
*::before {
  margin: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 10px;

  @media only screen and (max-width: $bp-largest) {
    font-size: 9px;
  }

  @media only screen and (max-width: $bp-smallest) {
    font-size: 8px; // 1 rem = 8px; (8px/16px) * 100 = 50%;
  }
}

body {
  box-sizing: border-box;
  font-weight: 400;
  color: var(--color-text-dark);
}
