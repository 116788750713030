.checkout {
  &-wrapper {
    padding: 6rem;

    @include media-breakpoint-down(lg) {
      padding: 0rem 2rem;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 3.6rem;
    color: #000000;

    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }
  }

  &-item-text {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    color: #000000;

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }

  &-information-text {
    font-size: 1.5rem;
  }

  &-modal-check-course--container {
    max-height: 90vh;
  }

  &-modal-check-course--content-container {
    overflow-y: scroll;
    max-height: 85%;
  }

  &-modal-check-course-title {
    font-size: 1.8rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }
}

.checkout-item {
  &-container {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
    border-radius: 1.2rem;
    padding: 1.5rem;
  }

  &-img {
    // width: 12.5rem;
    aspect-ratio: 346/180;
    height: 12.5rem;
    border-radius: 1rem;
    overflow: hidden;

    @include media-breakpoint-down(xl) {
      // width: 8rem;
      height: 8rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    position: relative;
    flex: 1;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  &-text {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #000000;

    &.purchase {
      font-size: 1.8rem;
      margin-left: 1rem;

      @include media-breakpoint-down(xl) {
        font-size: 1.6rem;
      }

      @include media-breakpoint-down(lg) {
        font-size: 1.6rem;
      }
    }

    @include media-breakpoint-down(xl) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }

  &-price-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;

    @include media-breakpoint-down(xl) {
      margin-top: 2rem;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 2rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-price {
    // position: absolute;
    // bottom: 1rem;
    // left: 2rem;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    color: var(--color-primary-blue);

    @include media-breakpoint-down(xl) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }

  &-sub {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-text-disable);
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }

  &-description {
    display: inline-block;

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  &-desc-title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @include media-breakpoint-down(xl) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }

  &-desc-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #525252;
    padding-right: 2rem;

    @include media-breakpoint-down(xl) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
      padding-right: 0;
    }
  }
}

// .modal-check-course {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */

//   &::-webkit-scrollbar {
//     display: none; /* Chrome, Safari, Opera */
//   }
// }

.icon-card {
  &.has-shadow {
    box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
  }

  &.small {
    width: 24px;
    height: 24px;

    img {
      width: 18px;
    }
  }

  border: 1px solid var(--color-element-disable);
  border-radius: 0.4rem;

  img {
    margin: 0;
  }
}

.debit-information {
  height: 37px;
  display: flex;
  align-items: center;
}

.font {
  &-title {
    font-size: 24px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      font-weight: normal;
    }
  }

  &-label {
    font-size: 16px;

    &.is-label {
      font-weight: 600;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
