.landing-card {
  &-wrapper {
    // max-width: 420px;
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
    border-radius: 1.2rem;
    padding: 1.6rem;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
  }

  &-content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: row;
      gap: 10px;
    }
  }

  &-right-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      width: calc((100% - 10px) / 2);
    }
  }

  &-left-container {
    @include media-breakpoint-down(md) {
      width: calc((100% - 10px) / 2);
    }
  }

  &-category-text {
    min-height: 2rem;
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      margin: 1.5rem 0;
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-image-container {
    border-radius: 1.4rem;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 346/180;
    position: relative;

    @include media-breakpoint-down(md) {
      // aspect-ratio: 149/120;
      margin-bottom: 1rem;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    object-fit: contain;
    height: 100%;

    &:hover {
      transform: scale(1.3);
      transform-origin: 50% 50%;
    }
  }

  &-category {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: #969696;
    margin-top: 1rem;
  }

  &-user-picture-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }

    .user-picture-container {
      &.company-logo {
        width: 58px;
        aspect-ratio: 1/1;
        border-radius: 10%;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          width: 36px;
        }
      }

      &.course-logo {
        width: 196px;
        aspect-ratio: 196/58;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          width: 130px;
          aspect-ratio: 130/36;
        }

        .landing-card-user-picture {
          object-fit: contain;
          object-position: left;
        }
      }
    }
  }

  &-user-picture {
    width: 100%;
    height: 100%;
  }

  &-user-name {
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    color: #666666;
    margin-bottom: 0;

    &.name-in-logo {
      margin-left: 10px;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #000000;
    margin-top: 1rem;
    // margin-bottom: 2rem;
    flex-grow: 1;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
      min-height: 0;
      margin-bottom: 2rem;
    }
  }

  &-price {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 1rem;
    color: var(--color-primary-blue);
    margin-top: 32px;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
      margin-top: 0rem;
    }
  }

  &-button {
    border: 1px solid #066bfb;
    background: #fff;
    width: 100%;
    height: 5rem;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #066bfb;
    text-align: center;
  }

  &__information {
    .hidden {
      visibility: hidden;
      height: 0px;
      width: 0px;
    }
    .active {
      color: white !important;
      background-color: #066bfb !important;
    }

    &:hover .hidden {
      visibility: visible;
      height: 100%;
      width: 100%;
    }

    &:hover .show {
      visibility: hidden;
      display: none;
      height: 0px;
      width: 0px;
    }
  }
}

.category-card {
  &-wrapper {
    background: #ffffff;

    @include media-breakpoint-down(lg) {
      box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
      border-radius: 1.2rem;
      padding: 1.6rem 0.8rem;
    }
  }

  &-image-container {
    border-radius: 1.4rem;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 346/180;
    position: relative;
    margin-bottom: 1rem;

    @include media-breakpoint-down(lg) {
      // aspect-ratio: 149/120;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &-image {
    width: 100%;
    transition: transform 0.6s;
    height: 24vh;
    object-fit: cover;

    &:hover {
      transform: scale(1.3);
      transform-origin: 50% 50%;
    }
  }

  &-category {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: #969696;
    margin-top: 1rem;
  }

  &-user-picture-container {
    width: 196px;
    aspect-ratio: 196/58;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      width: 128px;
    }

    .category-card-user-picture {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  &-user-name {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: #666666;

    @include media-breakpoint-down(lg) {
      font-size: 1.4rem;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    color: #000000;
    margin-top: 1rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }

  &-price {
    font-style: normal;
    font-weight: 700;
    font-size: 2.6rem;
    color: var(--color-primary-blue);

    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }

  &-button {
    border: 1px solid #066bfb;
    background: #fff;
    width: 100%;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #066bfb;

    &.secondary {
      border: none;
      background: #066bfb;
      color: #fff;
      font-weight: normal;
    }

    &.added-to-cart {
      background-color: #003fa5;
    }
  }
}
