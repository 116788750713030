.modal {
  &__select-input {
    width: 90%;
    max-width: none !important;

    .content {
      padding: 2rem 2.4rem 5rem 2.4rem;
      background-color: white;
    }
  }

  &__success,
  &__fail {
    width: 40vw;

    &.large {
      width: 55vw;
    }

    @include media-breakpoint-down(lg) {
      width: calc(100% - 1rem) !important;
    }

    &--container {
      margin-bottom: 5rem;
    }

    &--title {
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      color: #066bfb;
    }

    &--title2 {
      font-style: normal;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #066bfb;
    }

    &--sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      color: #000000;
    }

    &--button {
      background: #066bfb;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      color: #ffffff;
      width: 80%;
      height: 5.8rem;
    }

    &--button-no {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid;
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      color: #066bfb;
      border-color: #066bfb;
      width: 80%;
      height: 5.8rem;
    }
  }

  &__success--max-content {
    @extend .modal__success;
    min-width: 85rem;
  }

  &__blur {
    backdrop-filter: blur(2px);
  }

  &__header {
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      color: #000000;
    }

    &-close {
      position: absolute;
      right: 1rem;
      cursor: pointer;
    }
  }

  &__confirmation {
    width: 30vw;

    &__header {
      &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #000000;
      }

      &-close {
        position: absolute;
        right: 1rem;
        cursor: pointer;
        width: 18px;
        top: 15px;
      }
    }

    &--container {
      // margin-bottom: 5rem;
      padding: 5px;
    }

    &--content {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      color: #000000;
      line-height: 25px;
    }

    &--button {
      background: #066bfb;
      border-radius: 2px;
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      color: #ffffff;
      width: 30%;
      height: 3.8rem;
    }

    &--button-no {
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      color: #066bfb;
      border-color: #066bfb;
      width: 30%;
      height: 3.8rem;
    }
  }
}
