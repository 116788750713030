.breadcrumb-wrapper {
  display: flex;
  padding: 4rem 6rem;

  @include media-breakpoint-down(lg) {
    padding: 2rem;
  }
}

.breadcrumb-wrapper-2 {
  @extend .breadcrumb-wrapper;
  padding: 0;
}

.breadcrumb-text {
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #969696;
  padding-left: 1.5rem;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }

  &-active {
    cursor: text;
    font-weight: 600;
    color: #000000;
  }
}

.breadcrumb-arrow {
  padding-left: 1.5rem;
}
