.footer {
  &-wrapper {
    position: relative;
    padding: 1em;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
    padding-bottom: 50px;
    z-index: 1;
    a {
      font-style: normal;
      color: #ffffff;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  &-social-container {
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
  }

  &-container {
    position: relative;

    &::before {
      content: '';
      display: none;
      width: 100%;
      height: 1px;
      background-color: white;
      position: absolute;
      top: -50%;

      @include media-breakpoint-down(lg) {
        display: inline-block;
      }
    }
  }

  &-menu-container {
    position: relative;
    display: flex;
    gap: 3rem;
    padding-top: 5rem;
  }

  &-all-rights {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    color: #ffffff;
    position: absolute;
    right: 0;
  }

  &-top-pattern {
    background: linear-gradient(270deg, #660aff 0%, #0088d8 49.91%, #2affe3 100%);
    width: 100%;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.course-id {
  @include media-breakpoint-down(lg) {
    margin-bottom: 93px !important;
    bottom: unset !important;
  }
}

.product-id {
  @include media-breakpoint-down(lg) {
    margin-bottom: 93px !important;
    bottom: unset !important;
  }
}

.cart {
  @include media-breakpoint-down(lg) {
    margin-bottom: 180px !important;
    bottom: unset !important;
  }
}

.checkout {
  @include media-breakpoint-down(lg) {
    margin-bottom: 218px !important;
    bottom: unset !important;
  }
}

.no-bottomsheet {
  bottom: 0;
  margin: unset;
}
