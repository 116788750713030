.testimonial-user-container {
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

  > div {
    cursor: pointer;
    display: flex;
    flex: 1;
    align-content: flex-start;

    .details {
      margin-left: 16px;
      color: var(--color-primary-blue-dark);
      height: 60px;
      width: 200px;

      .bold {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      }

      .light {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .customer-avatar {
      height: 5.6rem;
      width: 5.6rem;
      border-radius: 50%;
    }
  }
}

.testimonials-container {
  .section-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
  }
}

.testimonial {
  display: flex;
  gap: 1rem;
  position: relative;
  min-height: 23.2rem;
  width: 100%;
  margin-top: 3.2rem;
  box-shadow: 0px 3rem 4rem 0 rgba(173, 173, 173, 0.2);
  background-color: white;
  border-radius: 10px;
  padding: 62px 36px 54px;

  .quote {
    font-weight: 800;
    font-size: 7.2rem;
    color: var(--color-primary-blue);
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--color-primary-blue-dark);
    line-height: 3.2rem;
    margin-top: -5px;
    margin-bottom: 0;
  }
}

.testimonial-bubble-arrow {
  position: absolute;
  top: -3rem;
  display: block;
  left: 40px;
  border-width: 1.5rem 1rem;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.testimonial-navigation-arrow {
  cursor: pointer;
  position: absolute;
  top: 55%;
  width: 15px;
  height: 15px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

@media only screen and (max-width: $bp-largest) {
  .testimonials-container {
    border-top: 1px solid rgb(241, 242, 243);
    margin-top: 12px;
    padding-top: 12px;

    .section-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }

    .testimonial {
      margin-top: 12px;
      padding: 42px 16px 24px;
      min-height: 150px;
    }

    .testimonial-bubble-arrow {
      top: -2.5rem;
      left: 8px;
    }

    .testimonial-user-container {
      margin-top: 20px;

      .customer-avatar {
        height: 32px;
        width: 32px;
      }
    }
  }
}
