.course {
  &-wrapper {
    padding: 6rem;
    min-height: 50vh;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      padding: 3rem;

      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 6rem);
        height: 2px;
        background-color: #3f3d561f;
        top: 0;
      }
    }

    .button-container {
      display: flex;
      gap: 2rem;
      margin-bottom: 4rem;

      button {
        width: 141px;
      }
    }
  }

  &-title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    color: #000000;

    @include media-breakpoint-down(lg) {
      font-size: 3.5rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
      font-weight: normal;
    }

    &-right {
      .button-all-course {
        width: fit-content;
        padding: 10px;
      }
    }
  }

  &-sub {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    color: #000000;
    cursor: pointer;

    &.is-link {
      color: var(--color-primary-blue);
    }

    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }
}
